import gsap from "../../../config/node_modules/gsap";
async function navigation () {
    
    var $ = require("../../../config/node_modules/jquery");

    $(document).ready(function() {

        var languageDropdown = $('.language-switcher');
        var languageHeight = $('.language').height();
        $('.language').on('mouseenter', function(e) {
            gsap.to(languageDropdown, {
                height: "auto",
            })
        });
        $('.language').on('mouseleave', function(e) {
            gsap.to(languageDropdown, {
                height: languageHeight,
            })
        });

        $('.navbar-toggler').click(function() {
            $('.navigation').toggleClass('menu-open').toggleClass('mega-menu__active');
            $('body').toggleClass('lock-scroll');
        });

        $(".nav-item.search").on('click', function(e) {
            $("#search__popup").addClass('active');
        });
    
        $(".search__popup__close__wrapper").on('click', function(e) {
            $("#search__popup").removeClass('active');
        });

        // Hide Navbar on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 0;
        var navbar = $('.navigation');
        var navbarHeight = $(navbar).outerHeight();
        /*var ctaBarMob = $('.cta-bar');*/
    
        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);
    
        function hasScrolled() {
    
            let st = $(window).scrollTop();
    
            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            if (navbarHeight > st) {
                $(navbar).addClass('nav-top');
            } else {
                $(navbar).removeClass('nav-top');
            }
    
            // If they scrolled down and are past the navbar, add class .nav-down.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop) {
                // Scroll Down
                $(navbar).removeClass('nav-up').addClass('nav-down');
                /*$(ctaBarMob).addClass('show-cta');*/
            } else {
                // Scroll Up
                if (st + $(window).height() < $(document).height()) {
                    $(navbar).removeClass('nav-down').addClass('nav-up');
                    /*$(ctaBarMob).removeClass('show-cta');*/
                }
            }
    
            lastScrollTop = st;
        }

        $(window).scroll(function() {
            if ($(window).scrollTop() >= 40) {
                $('.navigation').addClass('fixed-header');
                $('.navigation').removeClass('mega-menu__active');
            } else {
                $('.navigation').removeClass('fixed-header');
            }

            didScroll = true;
        });

        if ($(window).width() <= 991.98) {
            $('.nav-item.main-nav .dropdown-arrow').on('click', function(e) {
                let otherMegaMenus = $('.mega-menu');
    
                let hoofdmenuID = $(this).attr('data-hoofdmenu');
                let currentMegaMenu = $('.mega-menu[data-megamenu = ' + hoofdmenuID + ']'),
                    isShowing = currentMegaMenu.is(":visible");

                $('.nav-item.main-nav').removeClass("is-active");
    
                // Hide all
                otherMegaMenus.hide();

    
                // If our menu isn't showing, show it
                if (!isShowing) {
                    currentMegaMenu[0].previousElementSibling.classList.add("is-active");
                    currentMegaMenu.show();
                }
    
                e.preventDefault();
            });
    
            $('.nav-item .sub-dropdown-arrow').on('click', function(e) {
                let otherSubMenus = $('.dropdown-menu');
    
                let submenuID = $(this).attr('data-id');
                let currentSubMenu = $('.dropdown-menu[data-id = ' + submenuID + ']'),
                    isShowing = currentSubMenu.is(":visible");
    
                // Hide all
                otherSubMenus.hide();
    
                // If our menu isn't showing, show it
                if (!isShowing) {
                    currentSubMenu.show();
                }
    
                e.preventDefault();
            });
    
        } else {
            $('.nav-item.main-nav').on('mouseenter', function() {
                var nav = $('.navigation');
                var megaMenuWrapper = $('.mega-menu-wrapper');
                var otherMegaMenus = $('.mega-menu');
    
                $('.nav-item.main-nav').removeClass('hovered-nav-item');
                $(this).addClass('hovered-nav-item');
    
                if ($(this).hasClass('dropdown')) {
                    let hoofdmenuID = $(this).children('.nav-link').attr('data-hoofdmenu');
                    let currentMegaMenu = $('.mega-menu[data-megamenu = ' + hoofdmenuID + ']');
    
                    $(nav).addClass('mega-menu__active');
                    $('body').addClass('mega-menu__overlay');
    
                    let currentMegaMenuHeight = $(currentMegaMenu).outerHeight(true);
                    $(megaMenuWrapper).css("height", currentMegaMenuHeight + 'px');
    
                    $(otherMegaMenus).removeClass('active');
                    $(currentMegaMenu).addClass('active');
                } else {
                    $(otherMegaMenus).removeClass('active');
    
                    $(nav).removeClass('mega-menu__active');
                    $('body').removeClass('mega-menu__overlay');
                }
            });
    
            $('.navigation').on('mouseleave', function() {
                var nav = $('.navigation');
                var otherMegaMenus = $('.mega-menu');
    
                $('.nav-item.main-nav').removeClass('hovered-nav-item');
    
                $(otherMegaMenus).removeClass('active');
                $(nav).removeClass('mega-menu__active');
                $('body').removeClass('mega-menu__overlay');
            });

        }

        

    });

 } export {
    navigation
 }