import { owlCarousel } from "../../../config/node_modules/owl.carousel";

async function blogSlider() {
    
    var $ = require("../../../config/node_modules/jquery");

    $(document).ready(function() {

        $(".blog-slider__container").length && $(".blog-slider__container").owlCarousel({
            loop: false,
            dots: false,
            nav: 1,
            smartSpeed: 700,
            navText: ['<span class="fas fa-arrow-left"></span>', '<span class="fas fa-arrow-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                    margin: 20,
                },
                767: {
                    items: 2,
                    margin: 20,
                },
                1024: {
                    items: 2.5,
                    margin: 30,
                },
                1200: {
                    items: 3,
                    margin: 30,
                }
            }
        });

    });
}

export {
    blogSlider
}