import '../../config/node_modules/regenerator-runtime/runtime';

// -----------------------------

import "../../config/node_modules/jquery/dist/jquery.min";
import "../../config/node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "../../config/node_modules/jquery-ui/dist/jquery-ui.min";
import "../../config/node_modules/jquery.easing/jquery.easing.min";
import "../../config/node_modules/jquery.countdown/jquery.countdown.js";
import "../../config/node_modules/@fancyapps/fancybox/dist/jquery.fancybox";
import "../../config/node_modules/isotope-layout/js/isotope";
import "./scripts/nouislider";
// import "./scripts/owl"; // Yarn added
import "./scripts/instashow/elfsight-instagram-feed";
import "./scripts/validate";
import "./scripts/mixitup";
import "./scripts/appear";
import "./scripts/wow";
// import "./scripts/respond"; // Depricated
// import "./scripts/revolution.min"; // Not used
import { video } from './scripts/video';
video();
import "./scripts/map-script";
import {navigation} from './scripts/navigation';
navigation();
import {imageSlider} from './scripts/imageSlider';
imageSlider();
import {blogSlider} from './scripts/blogSlider';
blogSlider();
import { siteNotification } from './scripts/siteNotification';
siteNotification();
import "./scripts/script";

import './styles/style';


