import { gsap } from "../../../config/node_modules/gsap";

async function siteNotification() {
  var siteNotification = document.querySelector(".site-notification");
  if (
    typeof siteNotification === "undefined" ||
    siteNotification === null ||
    siteNotification.length === 0
  )
    return;

  const hideDateAttr = siteNotification.getAttribute("hide-date");
  if (!hideDateAttr) return;

  const storedHideDate = sessionStorage.getItem("notification-hidedate");
  if (
    storedHideDate === hideDateAttr &&
    sessionStorage.getItem("notification-closed")
  ) {
    siteNotification.style.display = "none";
    return;
  }

  const closeButton = siteNotification.querySelector(
    ".site-notification__close"
  );
  if (closeButton) {
    closeButton.addEventListener("click", () => {
      gsap.to(siteNotification, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => {
          siteNotification.style.display = "none";
          sessionStorage.setItem("notification-closed", "true");
          sessionStorage.setItem("notification-hidedate", hideDateAttr);
        },
      });
    });
  }

  setInterval(() => {
    if (
      sessionStorage.getItem("notification-hidedate") === hideDateAttr &&
      sessionStorage.getItem("notification-closed")
    )
      return;

    const hideDate = parseInt(hideDateAttr);
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (currentTimestamp > hideDate) {
      gsap.to(siteNotification, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => (siteNotification.style.display = "none"),
      });
    } else {
      gsap.to(siteNotification, {
        duration: 0.5,
        autoAlpha: 1,
        display: "flex",
      });
    }
  }, 2500);
}

export { siteNotification };
