/**
 * Video Player Element
 *
 * Can be a vimeo, youtube or own hosted video.
 * - Vimeo will use oEmbed
 * - Youtube will use oEmbed
 * - Custom will use a video element
 *
 * @returns {Promise<void>}
 */
 async function video() {
	/**
	 * Get all video elements via the wrapper `.video`;
	 *
	 * Loop thorough all and setup fetch requests.
	 * Create a fake iframe to alter, then insert iframe on play click.
	 */
	let videos = document.querySelectorAll('.video');

	videos.forEach(video => {
		// Check if current video element has custom thumb. Set boolean.
		let hasCustomThumb = video.contains(video.querySelector('.custom-thumbnail'));

		// Check what kind of type current video is.
		// - youtube
		// - vimeo
		// - custom
		let type = video.getAttribute('data-type');
		let oEmbedUrl = video?.dataset.src
		let endpoint;
		switch (type) {
			case 'vimeo':
				endpoint = 'https://vimeo.com/api/oembed.json';
				break;
			case 'youtube':
				endpoint = 'https://www.youtube.com/oembed';
				break;
			default:
			// code block
		}

		getVideo(type, oEmbedUrl).then(data => {
			if (!hasCustomThumb) {
				video.querySelector('.video__overlay').append(createThumbImg(data.thumbnail_url, data.title));
			}
			console.dir(video.parentElement);
			if (video.parentElement.classList.contains('main-slider') || video.parentElement.classList.contains('background-video')) {
				let iframe = parseHTML(data.html);
				let src = iframe.getAttribute('src');
				src = src + '&mute=1&autoplay=1&controls=0&modestbranding=1';
				iframe.setAttribute('src', src);
				iframe.setAttribute('title', data.title);
				video.append(iframe);
			}

			video.querySelector('.video__play').addEventListener('click', function () {
				// Feed string into dom parser and create iframe element.
				let iframe = parseHTML(data.html);
				let src = iframe.getAttribute('src');
				src = src + '&autoplay=1';
				iframe.setAttribute('src', src);
				iframe.setAttribute('title', data.title);
				video.append(iframe);
				removeThumb(video);
			})

		}).catch((error) => {
			console.error('Video oEmbed Error:', error);
		});

		// If custom, just update src on click.
		if (type === 'custom') {
			video.querySelector('.video__play').addEventListener('click', function () {
				let src = video.querySelector('video').getAttribute('data-src');
				video.querySelector('video').setAttribute('src', src);
				removeThumb(video);
			});
		}

	});
}

export function getVideo(type, oEmbedUrl) {
	return new Promise((resolve, reject) => {
		let endpoint;
		switch (type) {
			case 'vimeo':
				endpoint = 'https://vimeo.com/api/oembed.json';
				break;
			case 'youtube':
				endpoint = 'https://www.youtube.com/oembed';
				break;
			default:
		}


		fetch(`${endpoint}?url=${encodeURIComponent(oEmbedUrl)}`, {
			method: 'get',
			cache: 'no-cache',
			mode: 'cors',
		})
			.then(response => response.json())
			.then(data => {
				resolve(data)
			}).catch(error => {
				reject(error)
			})
	})
}

/**
 * Parses a string of html and give back an iframe element.
 *
 * @param {string} html String of html.
 *
 * @returns {HTMLElement} iframe Iframe element.
 */
function parseHTML(html) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	return doc.querySelector('iframe');
}

/**
 * Create img element thumbnail.
 *
 * @param {string} src
 * @param {string} title
 *
 * @returns HTMLImageElement
 */
function createThumbImg(src, title) {
	let img = document.createElement('img');
	img.setAttribute('src', src);
	img.setAttribute('alt', title);
	return img;
}

/**
 * Remove the overlay and button smoothly!
 *
 * @param video
 *
 * @returns void
 */
function removeThumb(video) {
	// After appending iframe, remove thumbnail and button.
	// First start opacity animation.
	video.querySelector('.video__overlay').style.animationPlayState = 'running';
	setTimeout(() => {
		// After animation is done, remove element. 1sec.
		video.querySelector('.video__overlay').remove();
	}, 600);
}

export {
	video
}
