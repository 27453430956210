import { owlCarousel } from "../../../config/node_modules/owl.carousel";

async function imageSlider() {
    
    var $ = require("../../../config/node_modules/jquery");

    $(document).ready(function() {

        $(".image-slider__container").length && $(".image-slider__container").owlCarousel({
            loop: 1,
            dots: false,
            nav: 1,
            smartSpeed: 700,
            navText: ['<span class="fas fa-arrow-left"></span>', '<span class="fas fa-arrow-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                    margin: 20,
                },
                576: {
                    items: 1.25,
                    margin: 20,
                },
                1200: {
                    items: 1.5,
                    margin: 30,
                }
            }
        });

    });
}

export {
    imageSlider
}