import { owlCarousel } from "../../../config/node_modules/owl.carousel";

var $ = require('../../../config/node_modules/jquery');
window.jQuery = $;
window.$ = $;

(function(a) {
    function e() {
        if (a(".main-header").length) {
            var g = a(window).scrollTop(),
                b = a(".main-header"),
                c = a(".scroll-to-top");
            250 <= g ? (c.fadeIn(300)) : (c.fadeOut(300))
        }
    }
    e();
    a(".main-header li.dropdown ul").length && (a(".main-header li.dropdown").append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'), a(".main-header li.dropdown .dropdown-btn").on("click", function() {
        a(this).prev("ul").slideToggle(500)
    }), a(".navigation li.dropdown > a").on("click",
        function(a) {
            a.preventDefault()
        }));
    if (a(".main-slider .tp-banner").length) {
        var c = a(".main-slider"),
            h = c.attr("data-start-height");
        c = "'" + c.attr("data-slide-overlay") + "'";
        a(".main-slider .tp-banner").show().revolution({
            dottedOverlay: c,
            delay: 1E4,
            startwidth: 1200,
            startheight: h,
            hideThumbs: 600,
            thumbWidth: 80,
            thumbHeight: 50,
            thumbAmount: 5,
            navigationType: "bullet",
            navigationArrows: "0",
            navigationStyle: "preview3",
            touchenabled: "on",
            onHoverStop: "off",
            swipe_velocity: .7,
            swipe_min_touches: 1,
            swipe_max_touches: 1,
            drag_block_vertical: !1,
            parallax: "mouse",
            parallaxBgFreeze: "on",
            parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],
            keyboardNavigation: "off",
            navigationHAlign: "center",
            navigationVAlign: "bottom",
            navigationHOffset: 0,
            navigationVOffset: 50,
            soloArrowLeftHalign: "left",
            soloArrowLeftValign: "bottom",
            soloArrowLeftHOffset: 20,
            soloArrowLeftVOffset: 0,
            soloArrowRightHalign: "right",
            soloArrowRightValign: "center",
            soloArrowRightHOffset: 20,
            soloArrowRightVOffset: 0,
            shadow: 0,
            fullWidth: "on",
            fullScreen: "off",
            spinner: "spinner4",
            stopLoop: "off",
            stopAfterLoops: -1,
            stopAtSlide: -1,
            shuffle: "off",
            autoHeight: "off",
            forceFullWidth: "on",
            hideThumbsOnMobile: "on",
            hideNavDelayOnMobile: 1500,
            hideBulletsOnMobile: "on",
            hideArrowsOnMobile: "on",
            hideThumbsUnderResolution: 0,
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            startWithSlide: 0,
            videoJsPath: "",
            fullScreenOffsetContainer: ""
        })
    }
    if (a(".client-testimonial-carousel").length && a(".client-thumbs-carousel").length) {
        var d = a(".client-testimonial-carousel"),
            f = a(".client-thumbs-carousel"),
            b = !1;
        d.owlCarousel({
            loop: !0,
            items: 1,
            margin: 0,
            nav: !0,
            navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
            dots: !1,
            autoplay: !0,
            autoplayTimeout: 5E3
        }).on("changed.owl.carousel", function(a) {
            b || (b = !1, f.trigger("to.owl.carousel", [a.item.index, 500, !0]), b = !1)
        });
        f.owlCarousel({
            loop: !0,
            margin: 25,
            items: 1,
            nav: !1,
            navText: ['<span class="icon fas fa-arrow-left"></span>', '<span class="icon fas fa-arrow-right"></span>'],
            dots: !1,
            center: !1,
            autoplay: !0,
            autoplayTimeout: 5E3,
            responsive: {
                0: {
                    items: 1,
                    autoWidth: !1
                },
                400: {
                    items: 1,
                    autoWidth: !1
                },
                600: {
                    items: 1,
                    autoWidth: !1
                },
                1E3: {
                    items: 1,
                    autoWidth: !1
                },
                1200: {
                    items: 1,
                    autoWidth: !1
                }
            }
        }).on("click", ".owl-item", function() {
            d.trigger("to.owl.carousel", [a(this).index(), 500, !0])
        }).on("changed.owl.carousel", function(a) {
            b || (b = !0, d.trigger("to.owl.carousel", [a.item.index, 500, !0]), b = !1)
        })
    }
    a(".time-countdown").length && a(".time-countdown").each(function() {
        var b = a(this),
            c = a(this).data("countdown");
        b.countdown(c, function(b) {
            a(this).html(b.strftime('<div class="counter-column"><div class="inner"><span class="count">%D</span>Days</div></div> <div class="counter-column"><div class="inner"><span class="count">%H</span>Hours</div></div>  <div class="counter-column"><div class="inner"><span class="count">%M</span>Mins</div></div>  <div class="counter-column"><div class="inner"><span class="count">%S</span>Secs</div></div>'))
        })
    });
    a(".filter-list").length && a(".filter-list").mixItUp({});
    a(".sponsors-carousel").length && a(".sponsors-carousel").owlCarousel({
        loop: !0,
        margin: 30,
        nav: !0,
        smartSpeed: 700,
        autoplay: 4E3,
        navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            600: {
                items: 2
            },
            800: {
                items: 3
            },
            1024: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    });
    a(".single-item-carousel").length && a(".single-item-carousel").owlCarousel({
        loop: !0,
        margin: 30,
        nav: !0,
        smartSpeed: 700,
        autoplay: 4E3,
        navText: ['<span class="fas fa-angle-left"></span>',
            '<span class="fas fa-angle-right"></span>'
        ],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            600: {
                items: 1
            },
            800: {
                items: 1
            },
            1024: {
                items: 1
            }
        }
    });
    a(".three-item-carousel").length && a(".three-item-carousel").owlCarousel({
        loop: !0,
        margin: 30,
        nav: !0,
        smartSpeed: 700,
        autoplay: 4E3,
        navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            600: {
                items: 1
            },
            800: {
                items: 2
            },
            1024: {
                items: 3
            }
        }
    });
    a(".lightbox-image").length && a(".lightbox-image").fancybox({
        openEffect: "fade",
        closeEffect: "fade",
        helpers: {
            media: {}
        }
    });
    a("#contact-form").length && a("#contact-form").validate({
        rules: {
            username: {
                required: !0
            },
            email: {
                required: !0,
                email: !0
            },
            website: {
                required: !0
            },
            subject: {
                required: !0
            },
            message: {
                required: !0
            }
        }
    });
    if (a(".scroll-to-target").length) a(".scroll-to-target").on("click", function() {
        var b = a(this).attr("data-target");
        a("html, body").animate({
            scrollTop: a(b).offset().top
        }, 1E3)
    });
    a(".wow").length && (new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: !1,
        live: !0
    })).init();
    a(window).on("scroll", function() {
        e()
    });
    a(window).on("load", function() {
        a(".preloader").length && a(".preloader").delay(200).fadeOut(500)
    })
})(window.jQuery);
// (function(a){function e(){if(a(".main-header").length){var g=a(window).scrollTop(),b=a(".main-header"),c=a(".scroll-to-top");250<=g?(b.addClass("fixed-header"),c.fadeIn(300)):(b.removeClass("fixed-header"),c.fadeOut(300))}}e();a(".main-header li.dropdown ul").length&&(a(".main-header li.dropdown").append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'),a(".main-header li.dropdown .dropdown-btn").on("click",function(){a(this).prev("ul").slideToggle(500)}),a(".navigation li.dropdown > a").on("click",
// function(a){a.preventDefault()}));if(a(".main-slider .tp-banner").length){var c=a(".main-slider"),h=c.attr("data-start-height");c="'"+c.attr("data-slide-overlay")+"'";a(".main-slider .tp-banner").show().revolution({dottedOverlay:c,delay:1E4,startwidth:1200,startheight:h,hideThumbs:600,thumbWidth:80,thumbHeight:50,thumbAmount:5,navigationType:"bullet",navigationArrows:"0",navigationStyle:"preview3",touchenabled:"on",onHoverStop:"off",swipe_velocity:.7,swipe_min_touches:1,swipe_max_touches:1,drag_block_vertical:!1,
// parallax:"mouse",parallaxBgFreeze:"on",parallaxLevels:[7,4,3,2,5,4,3,2,1,0],keyboardNavigation:"off",navigationHAlign:"center",navigationVAlign:"bottom",navigationHOffset:0,navigationVOffset:50,soloArrowLeftHalign:"left",soloArrowLeftValign:"bottom",soloArrowLeftHOffset:20,soloArrowLeftVOffset:0,soloArrowRightHalign:"right",soloArrowRightValign:"center",soloArrowRightHOffset:20,soloArrowRightVOffset:0,shadow:0,fullWidth:"on",fullScreen:"off",spinner:"spinner4",stopLoop:"off",stopAfterLoops:-1,stopAtSlide:-1,
// shuffle:"off",autoHeight:"off",forceFullWidth:"on",hideThumbsOnMobile:"on",hideNavDelayOnMobile:1500,hideBulletsOnMobile:"on",hideArrowsOnMobile:"on",hideThumbsUnderResolution:0,hideSliderAtLimit:0,hideCaptionAtLimit:0,hideAllCaptionAtLilmit:0,startWithSlide:0,videoJsPath:"",fullScreenOffsetContainer:""})}if(a(".client-testimonial-carousel").length&&a(".client-thumbs-carousel").length){var d=a(".client-testimonial-carousel"),f=a(".client-thumbs-carousel"),b=!1;d.owlCarousel({loop:!0,items:1,margin:0,
// nav:!0,navText:['<span class="fas fa-angle-left"></span>','<span class="fas fa-angle-right"></span>'],dots:!1,autoplay:!0,autoplayTimeout:5E3}).on("changed.owl.carousel",function(a){b||(b=!1,f.trigger("to.owl.carousel",[a.item.index,500,!0]),b=!1)});f.owlCarousel({loop:!0,margin:25,items:1,nav:!1,navText:['<span class="icon fas fa-arrow-left"></span>','<span class="icon fas fa-arrow-right"></span>'],dots:!1,center:!1,autoplay:!0,autoplayTimeout:5E3,responsive:{0:{items:1,autoWidth:!1},400:{items:1,
// autoWidth:!1},600:{items:1,autoWidth:!1},1E3:{items:1,autoWidth:!1},1200:{items:1,autoWidth:!1}}}).on("click",".owl-item",function(){d.trigger("to.owl.carousel",[a(this).index(),500,!0])}).on("changed.owl.carousel",function(a){b||(b=!0,d.trigger("to.owl.carousel",[a.item.index,500,!0]),b=!1)})}a(".time-countdown").length&&a(".time-countdown").each(function(){var b=a(this),c=a(this).data("countdown");b.countdown(c,function(b){a(this).html(b.strftime('<div class="counter-column"><div class="inner"><span class="count">%D</span>Days</div></div> <div class="counter-column"><div class="inner"><span class="count">%H</span>Hours</div></div>  <div class="counter-column"><div class="inner"><span class="count">%M</span>Mins</div></div>  <div class="counter-column"><div class="inner"><span class="count">%S</span>Secs</div></div>'))})});
// a(".filter-list").length&&a(".filter-list").mixItUp({});a(".sponsors-carousel").length&&a(".sponsors-carousel").owlCarousel({loop:!0,margin:30,nav:!0,smartSpeed:700,autoplay:4E3,navText:['<span class="fas fa-angle-left"></span>','<span class="fas fa-angle-right"></span>'],responsive:{0:{items:1},480:{items:2},600:{items:2},800:{items:3},1024:{items:4},1200:{items:5}}});a(".single-item-carousel").length&&a(".single-item-carousel").owlCarousel({loop:!0,margin:30,nav:!0,smartSpeed:700,autoplay:4E3,navText:['<span class="fas fa-angle-left"></span>',
// '<span class="fas fa-angle-right"></span>'],responsive:{0:{items:1},480:{items:1},600:{items:1},800:{items:1},1024:{items:1}}});a(".three-item-carousel").length&&a(".three-item-carousel").owlCarousel({loop:!0,margin:30,nav:!0,smartSpeed:700,autoplay:4E3,navText:['<span class="fas fa-angle-left"></span>','<span class="fas fa-angle-right"></span>'],responsive:{0:{items:1},480:{items:1},600:{items:1},800:{items:2},1024:{items:3}}});a(".lightbox-image").length&&a(".lightbox-image").fancybox({openEffect:"fade",
// closeEffect:"fade",helpers:{media:{}}});a("#contact-form").length&&a("#contact-form").validate({rules:{username:{required:!0},email:{required:!0,email:!0},website:{required:!0},subject:{required:!0},message:{required:!0}}});if(a(".scroll-to-target").length)a(".scroll-to-target").on("click",function(){var b=a(this).attr("data-target");a("html, body").animate({scrollTop:a(b).offset().top},1E3)});a(".wow").length&&(new WOW({boxClass:"wow",animateClass:"animated",offset:0,mobile:!1,live:!0})).init();
// a(window).on("scroll",function(){e()});a(window).on("load",function(){a(".preloader").length&&a(".preloader").delay(200).fadeOut(500)})})(window.jQuery);
